import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Embossing & Debossing",
  "description": "Raising or lowering the surface of an object for aesthetic purposes.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Powder-Coating", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Embossing or `}<em parentName="p">{`debossing`}</em>{` is a process to create reliefs across a surface. The process is done by applying heat pressure to a pre-made stamp on top (`}<strong parentName="p">{`emboss`}</strong>{`), or bottom (`}<strong parentName="p">{`deboss`}</strong>{`) of the surface. Embossing process will create a raised surface, while the debossing process will create a sunken surface. These processes are fast and cost-effective for batch productions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      